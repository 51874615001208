<template>
<div>
    <div class="text-center mt-5" v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" >
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>

        <div v-if="!cargando" class="row">
            <div v-for="(item, index) in contenidos" :key="index" class="col-md-6 mb-3" :class="{ 'col-md-12': (index === 0) }">
                <div class="card rounded-3 shadow">
                    <div style="height: 150px; overflow: hidden;" @click="verpublicacion(item.idcontenido)">
                        <img :src="archivospublicos+'/contenidos/'+ item.fotoportada" alt="" class="img-fluid puntero card-img-top rounded-3">
                    </div>
                    <!-- <div class="puntero" @click="verpublicacion(item.idcontenido)" v-if="item.fotoportada != null" :style="'background-image: url('+ archivospublicos +'/contenidos/'+ item.fotoportada +');   background-repeat: no-repeat; height: 220px;  background-size: 100% '"></div> -->
                    <div class="p-4 border-top">
                        <i class="fas fa-thumbtack float-end text-secondary" v-if="item.anclaje==1"></i>
                        <a @click="verpublicacion(item.idcontenido)" class="h5 border-0 text-black"> {{item.titulo}} </a> 
                        <div class="text-secondary"> <i class="far fa-calendar-alt me-1"></i> {{formatoFecha(item.fechainicio)}} </div>
                        <div class="pb-2">{{item.resumen}}</div>

                        <div class="mt-3">
                            <span v-for="cat in item.categorias" :key="cat.idcontenidocategoria" class="badge bg-light border text-secondary me-2"> {{cat.nombrecategoria}} </span>                             
                        </div> 
                    </div>

                </div>
            </div>
        </div>
</div>
</template>

<script>

import { ref, onMounted } from 'vue'
import axios from "axios";
import moment from 'moment'

import { useRoute, useRouter } from 'vue-router'

export default {
  setup(){
      
      const router = useRouter()
      const route = useRoute()
      const cargando = ref(true)

      const contenidos = ref({})
   
      const consultarcontenidos  = () => {
        axios.get('/api/publicacionesactivas').then( response => {
          contenidos.value = response.data.data
          cargando.value = false;
        })
      }

      const formatoFecha = (date) => {
            return moment(date).format('D MMMM [de] YYYY');
        }

      const verpublicacion = (id) => {
        router.push({ path: '/publicacionview/'+id })
      }

      

      onMounted(() => {

          consultarcontenidos()

      })

      return {  
        contenidos,
        cargando,
        
        //Funciones
        formatoFecha,
        verpublicacion
        } 

    }
}
</script>
<style scoped>
 
    a:hover, a:focus {
        background-size: 100% 2px;
        cursor: pointer;
    }

    .puntero {
        cursor: pointer;
    }
 
   
</style>
