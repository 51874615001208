<template>
    <div>
        <!-- DASHBOARD -->
                      <!-- Informes Academicos -->
              <div class="position-relative p-4 colorfondocuadros colorfuentecuadros" style="margin-top: 50px; border-radius: 10px;">
                <img src="imagenes/boletin.png" class="position-absolute mb-3  top-0 start-50 translate-middle" style="width: 180px;">

                <div class="lh-1" style="padding-top: 70px;">
                  <span class="h4 titulosg">Informes Académicos </span> <br>
                  <span class="text-secondary" style="font-size: 11px;">Calificaciónes y Promedios</span><br>
                  <span class="h2 titulosg"> {{anio}} </span>  <br>

                  <button class="btn btn-primary mt-2" @click="verinforme">Ver Informes</button>
                </div>
              </div>

    </div>
</template>
<script>

import { ref, onMounted } from 'vue'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'

export default {
    setup(props) {

        const router = useRouter()
        const route = useRoute()
        
        const anio = ref()
 
        const formatoanio = () => {
            anio.value = moment().format('YYYY')
        }

        const verinforme = () => {
            router.push({ name: 'estudiantesinformes' })
        }

        onMounted(() => {
            formatoanio()
        })
            return{
                anio,

                verinforme
            }
    }
}
</script>

<style scoped>
 
.colorfondocuadros{
  background-color: #def0f7;
}

.colorfuentecuadros{
  color: #637da5;
}
</style>
