<template>
    <div class="row">

        <div class="col-md-8">
          <Publicaciones></Publicaciones>       
        </div>

        <div class="col-md-4">

          <!-- Estudiantes -->
          <Estudiantes v-if="infousuario.personascategoria_idpersonascategoria === 4"></Estudiantes>

          <!-- Cumpleaños -->
          <Cumpleanios></Cumpleanios>
          
        </div>

    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import axios from "axios";

import { useRoute, useRouter } from 'vue-router'

import Publicaciones from './Dashboard/PublicacionesEscritorio'
import Estudiantes from './Dashboard/Estudiantes'
import Cumpleanios from './Dashboard/Cumpleanios'

export default {
  components: {
    Publicaciones,
    Cumpleanios,
    Estudiantes
  },
  setup(props){
      
      const router = useRouter()
      const route = useRoute()

      const infousuario = ref([])
   
      const consultarinfousuario = () => {

          axios.get("/api/personalogueada").then(response => {
            
            infousuario.value = response.data;

            }).catch(function (error) {
              console.log("error" + error);
            });
      }

      const verpublicacion = (id) => {
        router.push({ path: '/publicacionview/'+id })
      }

      onMounted(() => {
          consultarinfousuario()
      })

      return {  
        infousuario,
        
        //Funciones
        verpublicacion
        } 

    }
}
</script>

